import React, { useEffect, useState } from "react";
import { Bar, Doughnut, Pie } from "react-chartjs-2";
import { fetchAllKaduna, fetchAllLagos, fetchCompleteData } from "../utils";
import { DataItem } from "../interface";
import Header from "../components/Header";
import { Box, Card, Container, Grid } from "@mui/material";
import AOS from "aos";
import "aos/dist/aos.css";

const Analysis = () => {
    const [kadunaData, setKadunaData] = useState<DataItem[]>([]);
    const [lagosData, setLagosData] = useState<DataItem[]>([]);

    async function getKadunaData() {
        try {
            const response = await fetchAllKaduna();
            const { data } = response;
            setKadunaData(data);
        } catch (error: any) {
            console.warn(error);
        }
    }

    async function getLagosData() {
        try {
            const response = await fetchAllLagos();
            const { data } = response;
            setLagosData(data);
        } catch (error: any) {
            console.warn(error);
        }
    }

    useEffect(() => {
        getKadunaData();
        getLagosData();
    }, []);

    useEffect(() => {
        AOS.init();
    }, []);

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: "top" as const,
            },
            title: {
                display: false,
                text: "",
            },
        },
    };

    const cac_labels = ["Yes", "No"];

    // Count "Yes" and "No" for Kaduna
    const kadunaYesCount = kadunaData.filter(
        (item) => item.CAC_registered === "Yes"
    ).length;
    const kadunaNoCount = kadunaData.filter(
        (item) => item.CAC_registered === "No"
    ).length;

    // Count "Yes" and "No" for Lagos
    const lagosYesCount = lagosData.filter(
        (item) => item.CAC_registered === "Yes"
    ).length;
    const lagosNoCount = lagosData.filter(
        (item) => item.CAC_registered === "No"
    ).length;

    const cac_datasets = [
        {
            label: "Kaduna",
            data: [kadunaYesCount, kadunaNoCount],
            backgroundColor: "rgba(255, 99, 132, 0.8)",
        },
        {
            label: "Lagos",
            data: [lagosYesCount, lagosNoCount],
            backgroundColor: "rgba(53, 162, 235, 0.8)",
        },
    ];

    const cac_data = {
        labels: cac_labels,
        datasets: cac_datasets,
    };

    // Generate MSME_Sector labels from the data
    const msme_labels = Array.from(
        new Set([
            ...kadunaData.map((item) => item.MSME_Sector),
            ...lagosData.map((item) => item.MSME_Sector),
        ])
    );

    // Count occurrences of each MSME_Sector value for Kaduna
    const kadunaCount: { [key: string]: number } = {};
    kadunaData.forEach((dataItem) => {
        const sector = dataItem.MSME_Sector;
        if (sector) {
            kadunaCount[sector] = kadunaCount[sector]
                ? kadunaCount[sector] + 1
                : 1;
        }
    });

    // Count occurrences of each MSME_Sector value for Lagos
    const lagosCount: { [key: string]: number } = {};
    lagosData.forEach((dataItem) => {
        const sector = dataItem.MSME_Sector;
        if (sector) {
            lagosCount[sector] = lagosCount[sector]
                ? lagosCount[sector] + 1
                : 1;
        }
    });

    // MSME Dataset
    const msme_datasets = [
        {
            label: "Kaduna",
            data: msme_labels.map((label) => kadunaCount[label!] || 0),
            backgroundColor: "rgba(255, 206, 86, 0.8)",
        },
        {
            label: "Lagos",
            data: msme_labels.map((label) => lagosCount[label!] || 0),
            backgroundColor: "rgba(25, 106, 86, 0.8)",
        },
    ];

    const msme_data = {
        labels: msme_labels,
        datasets: msme_datasets,
    };

    // Ownership Type Dataset
    const ownershipType_labels = Array.from(
        new Set([
            ...kadunaData.map((dataItem) => dataItem.ownership),
            ...lagosData.map((dataItem) => dataItem.ownership),
        ])
    );

    const ownershipType_datasets = [
        {
            label: "Kaduna",
            data: ownershipType_labels.map((type) => {
                return kadunaData.filter(
                    (dataItem) => dataItem.ownership === type
                ).length;
            }),
            backgroundColor: "rgba(255, 99, 132, 0.5)",
        },
        {
            label: "Lagos",
            data: ownershipType_labels.map((type) => {
                return lagosData.filter(
                    (dataItem) => dataItem.ownership === type
                ).length;
            }),
            backgroundColor: "rgba(53, 162, 235, 0.5)",
        },
    ];

    const ownershipType_data = {
        labels: ownershipType_labels,
        datasets: ownershipType_datasets,
    };

    // Nature of Business Dataset
    const nature_of_business_labels = Array.from(
        new Set([
            ...kadunaData.map((dataItem) => dataItem.business_nature),
            ...lagosData.map((dataItem) => dataItem.business_nature),
        ])
    );

    const nature_of_business_datasets = [
        {
            label: "Kaduna",
            data: nature_of_business_labels.map((nature) => {
                return kadunaData.filter(
                    (dataItem) => dataItem.business_nature === nature
                ).length;
            }),
            backgroundColor: "rgba(75, 192, 192, 0.5)",
        },
        {
            label: "Lagos",
            data: nature_of_business_labels.map((nature) => {
                return lagosData.filter(
                    (dataItem) => dataItem.business_nature === nature
                ).length;
            }),
            backgroundColor: "rgba(153, 102, 255, 0.5)",
        },
    ];

    const nature_of_business_data = {
        labels: nature_of_business_labels,
        datasets: nature_of_business_datasets,
    };

    const stackedOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: "top" as const,
            },
            title: {
                display: true,
                text: "Chart.js Bar Chart",
            },
        },
    };

    // Filter and unique labels for Kaduna
    const kaduna_no_of_employees_label = [
        ...kadunaData.map((data) => data.no_of_employees?.trim()),
    ].filter(Boolean);

    // Filter and unique labels for Lagos
    const lagos_no_of_employees_label = [
        ...lagosData.map((data) => data.no_of_employees?.trim()),
    ].filter(Boolean);

    // Combine unique labels from both Kaduna and Lagos
    const uniqueLabels = [
        ...new Set([
            ...kaduna_no_of_employees_label,
            ...lagos_no_of_employees_label,
        ]),
    ];

    // Data for Kaduna
    const kaduna_no_of_employees_data = uniqueLabels.map(
        (label) =>
            kaduna_no_of_employees_label.filter((l) => l === label).length
    );

    // Data for Lagos
    const lagos_no_of_employees_data = uniqueLabels.map(
        (label) => lagos_no_of_employees_label.filter((l) => l === label).length
    );

    // Combine data for Kaduna and Lagos
    const combinedData = uniqueLabels.map((label) => ({
        label,
        kaduna: kaduna_no_of_employees_data[uniqueLabels.indexOf(label)],
        lagos: lagos_no_of_employees_data[uniqueLabels.indexOf(label)],
    }));

    const no_of_employees_data = {
        labels: uniqueLabels,
        datasets: [
            {
                label: "Kaduna",
                data: combinedData.map((item) => item.kaduna),
                backgroundColor: "rgba(255, 99, 132, 0.2)",
                borderColor: "rgba(255, 99, 132, 1)",
                borderWidth: 1,
            },
            {
                label: "Lagos",
                data: combinedData.map((item) => item.lagos),
                backgroundColor: "rgba(54, 162, 235, 0.2)",
                borderColor: "rgba(54, 162, 235, 1)",
                borderWidth: 1,
            },
        ],
    };

    // Filter and unique labels for Kaduna
    const kaduna_power_label = [
        ...kadunaData.map((data) => data.dailyUsageOfAlternatePower?.trim()),
    ].filter(Boolean);

    // Filter and unique labels for Lagos
    const lagos_power_label = [
        ...lagosData.map((data) => data.dailyUsageOfAlternatePower?.trim()),
    ].filter(Boolean);

    // Combine unique labels from both Kaduna and Lagos
    const uniquePowerLabels = [
        ...new Set([...kaduna_power_label, ...lagos_power_label]),
    ];

    // Data for Kaduna
    const kaduna_power_data = uniquePowerLabels.map(
        (label) => kaduna_power_label.filter((l) => l === label).length
    );

    // Data for Lagos
    const lagos_power_data = uniquePowerLabels.map(
        (label) => lagos_power_label.filter((l) => l === label).length
    );

    // Combine data for Kaduna and Lagos
    const combinedPowerData = uniquePowerLabels.map((label) => ({
        label,
        kaduna: kaduna_power_data[uniquePowerLabels.indexOf(label)],
        lagos: lagos_power_data[uniquePowerLabels.indexOf(label)],
    }));

    const alternate_power_data = {
        labels: uniquePowerLabels,
        datasets: [
            {
                label: "Kaduna",
                data: combinedPowerData.map((item) => item.kaduna),
                backgroundColor: "rgba(255, 99, 132, 0.2)",
                borderColor: "rgba(255, 99, 132, 1)",
                borderWidth: 1,
            },
            {
                label: "Lagos",
                data: combinedPowerData.map((item) => item.lagos),
                backgroundColor: "rgba(54, 162, 235, 0.2)",
                borderColor: "rgba(54, 162, 235, 1)",
                borderWidth: 1,
            },
        ],
    };

    const cardStyle = {
        padding: "15px",
        height: "100%",
    };

    return (
        <Box sx={{ margin: "2rem" }}>
            <Header title="Analysis Page" page="analysis" />

            <Container sx={{ mt: 5 }}>
                <Grid container spacing={5}>
                    <Grid item xs={12} sm={6}>
                        <Card style={cardStyle}>
                            <h2>CAC Business Registered per State</h2>
                            <Bar options={options} data={cac_data} />
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Card style={cardStyle}>
                            <h2>Business Ownership per State</h2>
                            <Bar options={options} data={ownershipType_data} />
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Card sx={{ p: 3 }}>
                            <h2>Distribution by Number of Employees</h2>
                            <Bar
                                options={stackedOptions}
                                data={no_of_employees_data}
                            />
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Card sx={{ p: 3 }}>
                            <h2>Daily Usage of Alternate Power</h2>
                            <Bar
                                options={stackedOptions}
                                data={alternate_power_data}
                            />
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Card style={cardStyle}>
                            <h2>SMSE Sector Per State</h2>
                            <Bar options={options} data={msme_data} />
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Card style={cardStyle}>
                            <h2>Nature of Business per State</h2>
                            <Bar
                                options={options}
                                data={nature_of_business_data}
                            />
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default Analysis;
