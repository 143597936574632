import React, { useEffect } from "react";
import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import { transform } from "ol/proj";

const OpenLayersMap = ({ coordinates }) => {
    useEffect(() => {
        // Extract latitude and longitude from the coordinate data
        const match = coordinates.match(/POINT \(([-\d.]+) ([-\d.]+)/);
        const latitude = parseFloat(match[2]);
        const longitude = parseFloat(match[1]);

        // Convert coordinates to the desired projection (e.g., EPSG:4326)
        const transformedCoordinates = transform(
            [longitude, latitude],
            "EPSG:4326",
            "EPSG:3857"
        );

        // Create the map
        const map = new Map({
            target: "map-container",
            layers: [
                new TileLayer({
                    source: new OSM(),
                }),
            ],
            view: new View({
                center: transformedCoordinates,
                zoom: 13,
            }),
        });
    }, [coordinates]);

    return <div id="map-container" style={{ height: "400px" }}></div>;
};

export default OpenLayersMap;

// import React, { useEffect, useRef } from "react";
// import { Map, View } from "ol";
// import TileLayer from "ol/layer/Tile";
// import OSM from "ol/source/OSM";

// const OpenLayersMap = ({ coordinates }) => {
//     const mapRef = useRef(null);

//     useEffect(() => {
//         const match = coordinates.match(/POINT \(([-\d.]+) ([-\d.]+)/);
//         const latitude = parseFloat(match[2]);
//         const longitude = parseFloat(match[1]);

//         const map = new Map({
//             target: mapRef.current ? mapRef.current : undefined,
//             layers: [
//                 new TileLayer({
//                     source: new OSM(),
//                 }),
//             ],
//             view: new View({
//                 center: [longitude, latitude],
//                 zoom: 13,
//             }),
//         });

//         return () => {
//             map.setTarget(undefined);
//         };
//     }, [coordinates]);

//     return <div ref={mapRef} style={{ height: "400px" }} />;
// };

// export default OpenLayersMap;
