import React, { useEffect, useState } from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";
//@ts-ignore
import NigeriaMap from "../assets/svg/map.svg";
import AOS from "aos";
import "aos/dist/aos.css";
import HighlightModal from "../components/HighlightModal";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    RadialLinearScale,
    ArcElement,
    PointElement,
    LineElement,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { fetchAllKaduna, fetchAllLagos } from "../utils";
import Header from "../components/Header";
import { DataItem } from "../interface";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    RadialLinearScale,
    ArcElement,
    CategoryScale,
    PointElement,
    LineElement
);

// Main Component
const Home = () => {
    const [kadunaData, setKadunaData] = useState<DataItem[]>([]);
    const [lagosData, setLagosData] = useState<DataItem[]>([]);

    async function getKadunaData() {
        try {
            const response = await fetchAllKaduna();
            const { data } = response;
            setKadunaData(data);
        } catch (error: any) {
            console.warn(error);
        }
    }

    async function getLagosData() {
        try {
            const response = await fetchAllLagos();
            const { data } = response;
            setLagosData(data);
        } catch (error: any) {
            console.warn(error);
        }
    }

    useEffect(() => {
        getKadunaData();
        getLagosData();
    }, []);

    useEffect(() => {
        AOS.init();
    }, []);

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: "top" as const,
            },
            title: {
                display: false,
                text: "",
            },
        },
    };

    const cac_labels = ["Yes", "No"];

    // Count "Yes" and "No" for Kaduna
    const kadunaYesCount = kadunaData.filter(
        (item) => item.CAC_registered === "Yes"
    ).length;
    const kadunaNoCount = kadunaData.filter(
        (item) => item.CAC_registered === "No"
    ).length;

    // Count "Yes" and "No" for Lagos
    const lagosYesCount = lagosData.filter(
        (item) => item.CAC_registered === "Yes"
    ).length;
    const lagosNoCount = lagosData.filter(
        (item) => item.CAC_registered === "No"
    ).length;

    const cac_datasets = [
        {
            label: "Kaduna",
            data: [kadunaYesCount, kadunaNoCount],
            backgroundColor: "rgba(255, 99, 132, 0.8)",
        },
        {
            label: "Lagos",
            data: [lagosYesCount, lagosNoCount],
            backgroundColor: "rgba(53, 162, 235, 0.8)",
        },
    ];

    const cac_data = {
        labels: cac_labels,
        datasets: cac_datasets,
    };

    // Generate MSME_Sector labels from the data
    const msme_labels = Array.from(
        new Set([
            ...kadunaData.map((item) => item.MSME_Sector),
            ...lagosData.map((item) => item.MSME_Sector),
        ])
    );

    // Count occurrences of each MSME_Sector value for Kaduna
    const kadunaCount: { [key: string]: number } = {};
    kadunaData.forEach((dataItem) => {
        const sector = dataItem.MSME_Sector;
        if (sector) {
            kadunaCount[sector] = kadunaCount[sector] ? kadunaCount[sector] + 1 : 1;
        }
    });

    // Count occurrences of each MSME_Sector value for Lagos
    const lagosCount: { [key: string]: number } = {};
    lagosData.forEach((dataItem) => {
        const sector = dataItem.MSME_Sector;
        if (sector) {
            lagosCount[sector] = lagosCount[sector] ? lagosCount[sector] + 1 : 1;
        }
    });

    // MSME Dataset
    const msme_datasets = [
        {
            label: "Kaduna",
            data: msme_labels.map((label) => kadunaCount[label!] || 0),
            backgroundColor: "rgba(255, 206, 86, 0.8)",
        },
        {
            label: "Lagos",
            data: msme_labels.map((label) => lagosCount[label!] || 0),
            backgroundColor: "rgba(25, 106, 86, 0.8)",
        },
    ];

    const msme_data = {
        labels: msme_labels,
        datasets: msme_datasets,
    };

    // Ownership Type Dataset
    const ownershipType_labels = Array.from(
        new Set([
            ...kadunaData.map((dataItem) => dataItem.ownership),
            ...lagosData.map((dataItem) => dataItem.ownership),
        ])
    );

    const ownershipType_datasets = [
        {
            label: "Kaduna",
            data: ownershipType_labels.map((type) => {
                return kadunaData.filter(
                    (dataItem) => dataItem.ownership === type
                ).length;
            }),
            backgroundColor: "rgba(255, 99, 132, 0.5)",
        },
        {
            label: "Lagos",
            data: ownershipType_labels.map((type) => {
                return lagosData.filter(
                    (dataItem) => dataItem.ownership === type
                ).length;
            }),
            backgroundColor: "rgba(53, 162, 235, 0.5)",
        },
    ];

    const ownershipType_data = {
        labels: ownershipType_labels,
        datasets: ownershipType_datasets,
    };

    // Nature of Business Dataset
    const nature_of_business_labels = Array.from(
        new Set([
            ...kadunaData.map((dataItem) => dataItem.business_nature),
            ...lagosData.map((dataItem) => dataItem.business_nature),
        ])
    );

    const nature_of_business_datasets = [
        {
            label: "Kaduna",
            data: nature_of_business_labels.map((nature) => {
                return kadunaData.filter(
                    (dataItem) => dataItem.business_nature === nature
                ).length;
            }),
            backgroundColor: "rgba(75, 192, 192, 0.5)",
        },
        {
            label: "Lagos",
            data: nature_of_business_labels.map((nature) => {
                return lagosData.filter(
                    (dataItem) => dataItem.business_nature === nature
                ).length;
            }),
            backgroundColor: "rgba(153, 102, 255, 0.5)",
        },
    ];

    const nature_of_business_data = {
        labels: nature_of_business_labels,
        datasets: nature_of_business_datasets,
    };

    return (
        <>
            <Box
                sx={{
                    pt: 3,
                    marginBottom: "-30px",
                    backgroundColor: "#236d65",
                }}
            >
                <Header title="UNW" page="home" />
            </Box>
            <main className="fh main-home">
                <section className="header-section">
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <Box data-aos="zoom-in" data-aos-duration="1500">
                            <Typography variant="h4" className="header-text">
                                UNW-PPDC Dashboard
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                className="header-subtext"
                            >
                                A Dashboard for exploring and analyzing data for
                                women-owned businesses in Lagos and Kaduna,
                                Nigeria
                            </Typography>
                            <Typography className="header-subtext">
                                This dataset is curated by the PPDC and
                                sponsored by the UN Women.
                            </Typography>
                        </Box>
                        <Box data-aos="fade-right" data-aos-duration="2000">
                            <img
                                src={NigeriaMap}
                                className="header-map"
                                alt="nigeria map"
                            />
                        </Box>
                    </Box>
                </section>
            </main>

            <section
                className="highlight-section"
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginBottom: "2rem",
                }}
            >
                <Box sx={{ flex: "center", pt: 7 }}>
                    <Typography variant="h5">Data Highlight</Typography>
                </Box>

                <Box sx={{ width: "100%", mt: 5 }}>
                    <Grid
                        container
                        rowSpacing={1}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                        <Grid item xs={12} sm={6} md={3}>
                            <Paper className="highlight-paper">
                                <Box sx={{ mt: 3 }}>
                                    <Typography variant="body1">
                                        No. of CAC registered business per state
                                    </Typography>
                                    <HighlightModal
                                        title={"CAC Registered Business"}
                                        content={
                                            <Bar
                                                options={options}
                                                data={cac_data}
                                            />
                                        }
                                    />
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Paper className="highlight-paper">
                                <Box sx={{ mt: 3 }}>
                                    <Typography variant="body1">
                                        Types of Business Ownership per State
                                    </Typography>
                                    <HighlightModal
                                        title={"Types of Business Ownership"}
                                        content={
                                            <>
                                                <Bar
                                                    options={options}
                                                    data={ownershipType_data}
                                                />
                                            </>
                                        }
                                    />
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Paper className="highlight-paper">
                                <Box sx={{ mt: 3 }}>
                                    <Typography variant="body1">
                                        No. of MSME per sector
                                    </Typography>
                                    <HighlightModal
                                        title={"MSME per sector"}
                                        content={
                                            <Bar
                                                options={options}
                                                data={msme_data}
                                            />
                                        }
                                    />
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Paper className="highlight-paper">
                                <Box sx={{ mt: 3 }}>
                                    <Typography variant="body1">
                                        Nature of Business per State
                                    </Typography>
                                    <HighlightModal
                                        title={"Nature of Business"}
                                        content={
                                            <Bar
                                                options={options}
                                                data={nature_of_business_data}
                                            />
                                        }
                                    />
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>
                </Box>
            </section>

            <section className="about-section">
                <Box className="about">
                    <Typography variant="h5">
                        Process of data collection
                    </Typography>
                    {/* <hr /> */}
                    <Typography variant="body1" sx={{ mt: 2 }}>
                        In our mission to gather valuable insights into
                        women-owned businesses, we employed a comprehensive and
                        systematic approach for data collection. To ensure
                        accuracy and inclusiveness, we engaged a team of
                        dedicated enumerators who ventured into the field. Our
                        skilled enumerators conducted in-person visits to
                        various locations, where they interacted directly with
                        women entrepreneurs. Through structured interviews and
                        surveys, we gathered a wealth of information about their
                        businesses, challenges, successes, and aspirations. In
                        addition to field visits, we leveraged the power of
                        social media to reach a wider audience. We strategically
                        shared survey links through various social media
                        platforms, allowing women entrepreneurs to participate
                        conveniently and share their perspectives. This approach
                        helped us capture insights from a diverse range of
                        women-owned businesses, including those beyond our
                        direct field visits.
                    </Typography>
                    <Typography variant="body1" sx={{ mt: 2 }}>
                        The use of enumerators and social media channels enabled
                        us to create a comprehensive and representative dataset.
                        By combining on-the-ground interactions with online
                        surveys, we ensured that our research covered a broad
                        spectrum of women-owned businesses, accounting for
                        regional variations and diverse industry sectors.
                    </Typography>
                </Box>
            </section>

            <section className="copy">&copy; PPDC.org | UN Women</section>
        </>
    );
};

export default Home;
