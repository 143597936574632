// const API = "https://unw-18b8df682742.herokuapp.com"; // heroku
// const API = "http://localhost:3000"; // local
const API = "https://unwomen-api.vercel.app";

export const fetchAllData = async (page: number, limit: number) => {
    try {
        const response = await fetch(`${API}/data?page=${page}&limit=${limit}`);
        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error fetching data:", error);
        return [];
    }
};

export const fetchFilteredData = async (
    page: number,
    limit: number,
    filters: any
) => {
    const filterParams = new URLSearchParams(filters).toString();
    try {
        const response = await fetch(
            `${API}/data?page=${page}&limit=${limit}&${filterParams}`
        );
        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error fetching filtered data:", error);
        return [];
    }
};

export const fetchSingleData = async (id: string) => {
    try {
        const response = await fetch(`${API}/data/${id}`);
        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error fetching single data item:", error);
        return null;
    }
};

export const fetchAllLGAs = async () => {
    try {
        const response = await fetch(`${API}/lgas`);
        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error fetching LGAs:", error);
        return [];
    }
};

export const fetchKadunaLGAs = async () => {
    try {
        const response = await fetch(`${API}/kaduna-lgas`);
        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error fetching Kaduna LGAs:", error);
        return [];
    }
};

export const fetchLagosLGAs = async () => {
    try {
        const response = await fetch(`${API}/lagos-lgas`);
        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error fetching Lagos LGAs:", error);
        return [];
    }
};

export const fetchMSMESectors = async () => {
    try {
        const response = await fetch(`${API}/msme-sectors`);
        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error fetching MSME Sectors:", error);
        return [];
    }
};

export const fetchBusinessNatures = async () => {
    try {
        const response = await fetch(`${API}/business-natures`);
        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error fetching Business Natures:", error);
        return [];
    }
};


// ALL DATA
export const fetchCompleteData = async () => {
    try {
        const response = await fetch(`${API}/complete-data`);
        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error fetching data:", error);
        return [];
    }
};

export const fetchAllKaduna = async () => {
    try {
        const response = await fetch(`${API}/kaduna-data`);
        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error fetching data:", error);
        return [];
    }
};

export const fetchAllLagos = async () => {
    try {
        const response = await fetch(`${API}/lagos-data`);
        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error fetching data:", error);
        return [];
    }
};
