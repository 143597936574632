import React, { useEffect, useState } from "react";
import {
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Grid,
    Box,
    Button,
    TextField,
    Container,
    Card,
    CardContent,
    Typography,
    IconButton,
    Badge,
    Chip,
    CircularProgress,
} from "@mui/material";
import {
    fetchAllData,
    fetchAllLGAs,
    fetchBusinessNatures,
    fetchFilteredData,
    fetchKadunaLGAs,
    fetchLagosLGAs,
    fetchMSMESectors,
} from "../utils";
import {
    ArrowForward,
    ArrowLeft,
    ArrowLeftOutlined,
    ArrowRight,
    KeyboardArrowUp,
    Visibility,
} from "@mui/icons-material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AOS from "aos";
import "aos/dist/aos.css";
import Header from "../components/Header";

const MainDataComponent = () => {
    useEffect(() => {
        AOS.init();
    }, []);

    const [data, setData] = useState<any[]>([]);
    const [lgas, setLGAs] = useState<string[]>([]);
    const [kadunaLGAs, setKadunaLGAs] = useState<string[]>([]);
    const [lagosLGAs, setLagosLGAs] = useState<string[]>([]);
    const [msmeSectors, setMSMESectors] = useState<string[]>([]);
    const [businessNatures, setBusinessNatures] = useState<string[]>([]);
    const [businessNatureMenuItemTag, setBusinessNatureMenuItemTag] =
        useState<string>("All Business Types");
    const [menuItemTag, setMenuItemTag] = useState<string>("All LGAs");
    const [msmeSectorMenuItemTag, setMsmeSectorMenuItemTag] =
        useState<string>("All MSME Sectors");

    const [loading, setLoading] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState<number>(50);
    const [totalPages, setTotalPages] = useState(1);
    const [totalDataNumber, setTotalDataNumber] = useState(1);

    const [filters, setFilters] = useState({
        state: "",
        lga: "",
        businessNature: "",
        msmeSector: "",
    });

    const fetchData = async (page: number, limit: number, filters?: any) => {
        setLoading(true);
        try {
            const data = filters
                ? await fetchFilteredData(page, limit, filters)
                : await fetchAllData(page, limit);

            setData(data.data);
            setTotalPages(data.totalPages);
            setTotalDataNumber(data.totalDataNumber);
        } catch (error: any) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleFilterChange = (event) => {
        const { name, value } = event.target;
        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: value,
        }));
    };

    const handleApplyFilters = () => {
        try {
            setLoading(true);
            fetchFilteredData(1, limit, filters)
                .then((filteredData) => {
                    setData(filteredData.data);
                    setTotalPages(filteredData.totalPages);
                    setTotalDataNumber(filteredData.totalDataNumber);
                })
                .finally(() => setLoading(false));
        } catch (error) {
            console.info(error);
            setLoading(false);
        }
    };

    const handleClearFilters = () => {
        try {
            setLoading(true);
            setFilters({
                state: "",
                lga: "",
                businessNature: "",
                msmeSector: "",
            });
            fetchData(1, limit);
        } catch (error) {
            console.info(error);
        } finally {
            setLoading(false);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    useEffect(() => {
        fetchData(currentPage, limit);
    }, [currentPage]);

    useEffect(() => {
        const fetchKadunaAndLagosLGAs = async () => {
            const kadunaData = await fetchKadunaLGAs();
            setKadunaLGAs(kadunaData);

            const lagosData = await fetchLagosLGAs();
            setLagosLGAs(lagosData);
        };

        fetchKadunaAndLagosLGAs();
    }, []);

    useEffect(() => {
        const fetchOtherData = async () => {
            const msmeSectorsData = await fetchMSMESectors();
            setMSMESectors(msmeSectorsData);

            const businessNaturesData = await fetchBusinessNatures();
            setBusinessNatures(businessNaturesData);
        };

        fetchOtherData();
    }, []);

    const handleScrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    return (
        <Box sx={{ margin: "2rem" }}>
            <Header title="Data Page" page="data" />

            <Container maxWidth="lg" style={{ marginTop: "1rem" }}>
                {/* <Grid container spacing={2}> */}
                <Grid
                    container
                    spacing={2}
                    style={{ display: "flex", flexWrap: "nowrap" }}
                >
                    <Grid
                        item
                        xs={12}
                        md={4}
                        sx={{ marginTop: "2rem", flexShrink: 0 }}
                    >
                        <Card sx={{ p: 3 }}>
                            <Typography sx={{ mb: 4 }} variant="h6">
                                Filter
                            </Typography>
                            <FormControl
                                fullWidth
                                style={{ marginBottom: "1rem" }}
                            >
                                <InputLabel htmlFor="state-filter">
                                    State
                                </InputLabel>
                                <Select
                                    id="state-filter"
                                    name="state"
                                    value={filters.state}
                                    onChange={handleFilterChange}
                                >
                                    <MenuItem value="">All States</MenuItem>
                                    <MenuItem value="Kaduna">Kaduna</MenuItem>
                                    <MenuItem value="Lagos">Lagos</MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl
                                fullWidth
                                style={{ marginBottom: "1rem" }}
                            >
                                <InputLabel htmlFor="lga-filter">
                                    Local Government
                                </InputLabel>
                                <Select
                                    id="lga-filter"
                                    name="lga"
                                    value={filters.lga}
                                    onChange={handleFilterChange}
                                >
                                    <MenuItem value="">{menuItemTag}</MenuItem>
                                    {lgas.map((lga, index) => (
                                        <MenuItem key={index} value={lga}>
                                            {lga}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl
                                fullWidth
                                style={{ marginBottom: "1rem" }}
                            >
                                <InputLabel htmlFor="business-type-filter">
                                    Business Nature
                                </InputLabel>
                                <Select
                                    id="business-type-filter"
                                    name="businessNature"
                                    value={filters.businessNature}
                                    onChange={handleFilterChange}
                                >
                                    <MenuItem value="">
                                        {businessNatureMenuItemTag}
                                    </MenuItem>
                                    {businessNatures.map((type, index) => (
                                        <MenuItem key={index} value={type}>
                                            {type}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl
                                fullWidth
                                style={{ marginBottom: "1rem" }}
                            >
                                <InputLabel htmlFor="msme-sector-filter">
                                    MSME Sectors
                                </InputLabel>
                                <Select
                                    id="msme-sector-filter"
                                    name="msmeSector"
                                    value={filters.msmeSector}
                                    onChange={handleFilterChange}
                                >
                                    <MenuItem value="">
                                        {msmeSectorMenuItemTag}
                                    </MenuItem>
                                    {msmeSectors.map((sector, index) => (
                                        <MenuItem key={index} value={sector}>
                                            {sector}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleApplyFilters}
                                style={{ marginRight: "1rem" }}
                                size="small"
                            >
                                Apply Filters
                            </Button>
                            <Button
                                variant="contained"
                                color="warning"
                                onClick={handleClearFilters}
                                size="small"
                            >
                                Clear Filters
                            </Button>
                        </Card>
                    </Grid>

                    <Grid item xs={12} md={8}>
                        <Box
                            sx={{
                                marginTop: "2rem",
                                paddingLeft: { xs: 0, md: "2rem" },
                            }}
                        >
                            <Typography sx={{ mb: 0 }} variant="h6">
                                Browse Women-Owned Businesses
                            </Typography>
                            <Typography sx={{ mb: 2 }} variant="subtitle2">
                                {data && data.length > 0 && (
                                    <>
                                        <span>Data from&nbsp;</span>
                                        <span
                                            style={{
                                                color: "#696",
                                                textDecoration: "underline",
                                            }}
                                        >
                                            <strong>{totalDataNumber}</strong>
                                        </span>
                                        <span>&nbsp;Businesses</span>
                                    </>
                                )}
                            </Typography>

                            {loading ? (
                                <Box
                                    sx={{
                                        textAlign: "center",
                                        marginTop: "2rem",
                                    }}
                                >
                                    <CircularProgress color="info" />
                                    <p>
                                        <em>Loading data...</em>
                                    </p>
                                </Box>
                            ) : data.length <= 0 ? (
                                "No data"
                            ) : (
                                <Grid
                                    container
                                    spacing={2}
                                    justifyContent="center"
                                >
                                    {data.map((item, index) => (
                                        <Grid item key={index} xs={12} sm={6}>
                                            <Card
                                                className="card"
                                                data-aos="zoom-in-up"
                                                data-aos-duration="1500"
                                            >
                                                <CardContent sx={{ p: 2 }}>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent:
                                                                "space-between",
                                                            alignItems:
                                                                "center",
                                                        }}
                                                    >
                                                        <Box
                                                            sx={{
                                                                display: "flex",
                                                                alignItems:
                                                                    "center",
                                                            }}
                                                        >
                                                            <LocationOnIcon
                                                                sx={{
                                                                    color: "#169",
                                                                    fontSize:
                                                                        "14px",
                                                                }}
                                                            />
                                                            <Typography
                                                                variant="subtitle1"
                                                                component="span"
                                                                sx={{
                                                                    color: "#169",
                                                                }}
                                                            >
                                                                {item.state}
                                                            </Typography>
                                                        </Box>
                                                        <IconButton
                                                            color="success"
                                                            component="a"
                                                            href={`/data/${item._id}`}
                                                        >
                                                            <Visibility />
                                                        </IconButton>
                                                    </div>
                                                    <Typography variant="h6">
                                                        {item.business_name ||
                                                            item.business_name_2}
                                                    </Typography>
                                                    <Typography
                                                        variant="subtitle2"
                                                        color="textSecondary"
                                                    >
                                                        {item.MSME_Sector}
                                                    </Typography>
                                                    <Chip
                                                        sx={{ mt: 2 }}
                                                        label={
                                                            item.business_LGA
                                                        }
                                                        color="info"
                                                        variant="outlined"
                                                    />
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    ))}
                                </Grid>
                            )}
                        </Box>
                        <Box
                            sx={{
                                my: 10,
                                p: 3,
                                display: "flex",
                                justifyContent: "space-around",
                                alignItems: "center",
                                backgroundColor: "#f7f7f7",
                            }}
                        >
                            <Button
                                startIcon={<ArrowLeft />}
                                variant="contained"
                                color="primary"
                                onClick={handlePreviousPage}
                                disabled={currentPage === 1}
                                size="medium"
                            >
                                Previous
                            </Button>
                            <Typography>
                                <em>Showing page</em>{" "}
                                <strong>{currentPage}</strong> <em>of</em>{" "}
                                <strong>{totalPages}</strong>
                            </Typography>
                            <Button
                                endIcon={<ArrowRight />}
                                variant="contained"
                                color="secondary"
                                onClick={handleNextPage}
                                disabled={currentPage === totalPages}
                                size="medium"
                            >
                                Next
                            </Button>
                        </Box>
                    </Grid>
                    <IconButton
                        onClick={handleScrollToTop}
                        sx={{
                            position: "fixed",
                            bottom: "20px",
                            right: "20px",
                            backgroundColor: "primary.main",
                            color: "white",
                            "&:hover": {
                                backgroundColor: "primary.dark",
                            },
                        }}
                    >
                        <KeyboardArrowUp />
                    </IconButton>
                </Grid>
            </Container>
        </Box>
    );
};

export default MainDataComponent;