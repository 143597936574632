import React, { useState, useEffect } from "react";
import {
    Card,
    Box,
    Typography,
    Button,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    CardContent,
    Grid,
    Paper,
} from "@mui/material";
import ArrowLeftRounded from "@mui/icons-material/ArrowLeftRounded";
import { useParams, useNavigate } from "react-router-dom";
import { fetchSingleData } from "../utils";
import {
    AssistantDirection,
    BusinessCenter,
    Cases,
    Groups,
    Domain,
    CasesOutlined,
    Business,
    AdminPanelSettings,
    PersonPinCircle,
    EmailRounded,
    CallRounded,
    Money,
    AccountBalance,
    Wallet,
    AccountBalanceWallet,
    ProductionQuantityLimits,
    Navigation,
} from "@mui/icons-material";
import OpenLayersMap from "../components/LeafletMap";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "aos/dist/aos.css";
import Header from "../components/Header";
import { DataItem } from "../interface/index";

const DetailsPage = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [data, setData] = useState<DataItem | null>(null);

    const fetchData = async (id: string) => {
        try {
            const response = await fetchSingleData(id);
            setData(response[0]);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        if (id) {
            fetchData(id);
        }
    }, []);

    if (!data) {
        return (
            <div>
                <h2>Data not found</h2>
            </div>
        );
    }

    const handleBackClick = () => {
        navigate(-1);
    };

    return (
        <div style={{ margin: "2rem" }}>
            <Header title="Details" page="data" />
            <Box className="data-section">
                <Card
                    sx={{
                        boxShadow: "none",
                        py: 2,
                        backgroundColor: "#eff4eb",
                    }}
                    className="details-card"
                >
                    <Button onClick={handleBackClick}>
                        <ArrowLeftRounded sx={{ fontSize: "24px" }} /> Back
                    </Button>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Box textAlign="center">
                            <Typography variant="h5">
                                {data.business_name || data["Business name"]}
                            </Typography>
                            <Typography variant="body1" align="center">
                                RC: {data.RC_number || "N/A"}
                            </Typography>
                        </Box>
                    </Box>

                    <CardContent sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={8}>
                                {/* business info */}
                                <Accordion
                                    defaultExpanded={true}
                                    className="accordion"
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography className="heading-text">
                                            Business Information
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container spacing={5}>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <AssistantDirection className="icon" />
                                                    <Typography
                                                        variant="subtitle1"
                                                        className="details-header-text"
                                                    >
                                                        Business Address
                                                    </Typography>
                                                </Box>
                                                <Typography>
                                                    {data.business_address ||
                                                        "n/a"}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Navigation className="icon" />
                                                    <Typography
                                                        variant="subtitle1"
                                                        className="details-header-text"
                                                    >
                                                        Business LGA
                                                    </Typography>
                                                </Box>
                                                <Typography>
                                                    {data.business_LGA || "n/a"}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <BusinessCenter className="icon" />
                                                    <Typography
                                                        variant="subtitle1"
                                                        className="details-header-text"
                                                    >
                                                        Business Nature
                                                    </Typography>
                                                </Box>
                                                <Typography className="details-content-text">
                                                    {data.business_nature ||
                                                        "n/a"}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Cases className="icon" />
                                                    <Typography
                                                        variant="subtitle1"
                                                        className="details-header-text"
                                                    >
                                                        MSME Category Turnover
                                                    </Typography>
                                                </Box>
                                                <Typography className="details-content-text">
                                                    {data.MSMEcategory_turnover ||
                                                        "n/a"}
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={12} sm={6} md={4}>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Groups className="icon" />
                                                    <Typography
                                                        variant="subtitle1"
                                                        className="details-header-text"
                                                    >
                                                        Number of Employees
                                                        Range
                                                    </Typography>
                                                </Box>
                                                <Typography className="details-content-text">
                                                    {data.no_of_employees ||
                                                        "n/a"}
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={12} sm={6} md={4}>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Domain className="icon" />
                                                    <Typography
                                                        variant="subtitle1"
                                                        className="details-header-text"
                                                    >
                                                        MSME Sector
                                                    </Typography>
                                                </Box>
                                                <Typography className="details-content-text">
                                                    {data.MSME_Sector || "n/a"}
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={12} sm={6} md={4}>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <CasesOutlined className="icon" />
                                                    <Typography
                                                        variant="subtitle1"
                                                        className="details-header-text"
                                                    >
                                                        Business Category
                                                    </Typography>
                                                </Box>
                                                <Typography className="details-content-text">
                                                    {data.business_category ||
                                                        "n/a"}
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={12} sm={6} md={4}>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Business className="icon" />
                                                    <Typography
                                                        variant="subtitle1"
                                                        className="details-header-text"
                                                    >
                                                        Company Type
                                                    </Typography>
                                                </Box>
                                                <Typography className="details-content-text">
                                                    {data.company_type || "n/a"}
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={12} sm={6} md={4}>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <AdminPanelSettings className="icon" />
                                                    <Typography
                                                        variant="subtitle1"
                                                        className="details-header-text"
                                                    >
                                                        Ownership Type
                                                    </Typography>
                                                </Box>
                                                <Typography className="details-content-text">
                                                    {data.ownership || "n/a"}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>

                                {/* contact info */}
                                <Accordion className="accordion">
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography className="heading-text">
                                            Contact Information
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container spacing={5}>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <PersonPinCircle className="icon" />
                                                    <Typography
                                                        variant="subtitle1"
                                                        className="details-header-text"
                                                    >
                                                        Contact Person
                                                    </Typography>
                                                </Box>
                                                <Typography>
                                                    {data.Contact_person ||
                                                        "n/a"}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <EmailRounded className="icon" />
                                                    <Typography
                                                        variant="subtitle1"
                                                        className="details-header-text"
                                                    >
                                                        Email
                                                    </Typography>
                                                </Box>
                                                <Typography>
                                                    {data.Phone_number || "n/a"}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <CallRounded className="icon" />
                                                    <Typography
                                                        variant="subtitle1"
                                                        className="details-header-text"
                                                    >
                                                        Phone
                                                    </Typography>
                                                </Box>
                                                <Typography className="details-content-text">
                                                    {data.email || "n/a"}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>

                                {/* financial and compliance */}
                                <Accordion className="accordion">
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography className="heading-text">
                                            Financial and Compliance
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container spacing={5}>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Money className="icon" />
                                                    <Typography
                                                        variant="subtitle1"
                                                        className="details-header-text"
                                                    >
                                                        Source of Capital
                                                    </Typography>
                                                </Box>
                                                <Typography>
                                                    {data.main_source_of_capital ||
                                                        "n/a"}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <AccountBalance className="icon" />
                                                    <Typography
                                                        variant="subtitle1"
                                                        className="details-header-text"
                                                    >
                                                        Source of Loan
                                                    </Typography>
                                                </Box>
                                                <Typography>
                                                    {data.source_of_loan ||
                                                        "n/a"}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Wallet className="icon" />
                                                    <Typography
                                                        variant="subtitle1"
                                                        className="details-header-text"
                                                    >
                                                        Source of Grant
                                                    </Typography>
                                                </Box>
                                                <Typography className="details-content-text">
                                                    {data.source_of_grant ||
                                                        "n/a"}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <AccountBalanceWallet className="icon" />
                                                    <Typography
                                                        variant="subtitle1"
                                                        className="details-header-text"
                                                    >
                                                        Other source of Grant
                                                    </Typography>
                                                </Box>
                                                <Typography className="details-content-text">
                                                    {data.other_sources_of_grant ||
                                                        "n/a"}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <ProductionQuantityLimits className="icon" />
                                                    <Typography
                                                        variant="subtitle1"
                                                        className="details-header-text"
                                                    >
                                                        Major Source of Raw
                                                        Material
                                                    </Typography>
                                                </Box>
                                                <Typography className="details-content-text">
                                                    {data.major_source_of_raw_material ||
                                                        "n/a"}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>

                                {/* covid-19 impact */}
                                <Accordion className="accordion">
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography className="heading-text">
                                            COVID-19 Impact
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container spacing={5}>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Typography
                                                        variant="subtitle1"
                                                        className="details-header-text"
                                                    >
                                                        Impact of COVID-19 on
                                                        Sales
                                                    </Typography>
                                                </Box>
                                                <Typography>
                                                    {data.Impact_of_COVID_19_on_sales ||
                                                        "n/a"}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Typography
                                                        variant="subtitle1"
                                                        className="details-header-text"
                                                    >
                                                        Impact of COVID-19 on
                                                        Financial Habits
                                                    </Typography>
                                                </Box>
                                                <Typography>
                                                    {data.Impact_of_COVID_19_on_financial_habits ||
                                                        "n/a"}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>

                                {/* map location */}
                                <Accordion
                                    className="accordion"
                                    defaultExpanded={true}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography className="heading-text">
                                            Geo Location
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {data.geo_coordinates && (
                                            <OpenLayersMap
                                                coordinates={
                                                    data.geo_coordinates
                                                }
                                            />
                                        )}
                                        {!data.geo_coordinates &&
                                            "No coordinates"}
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Paper className="details-paper">
                                    <Typography className="heading-text">
                                        Registration Details
                                    </Typography>
                                    <p style={{ marginBottom: "3px" }}>
                                        CAC Registered:{" "}
                                        <span className="details-paper-text">
                                            {data.CAC_registered || "n/a"}
                                        </span>
                                    </p>
                                    <p
                                        style={{
                                            marginTop: 1,
                                            marginBottom: "3px",
                                        }}
                                    >
                                        Registration Year:{" "}
                                        <span className="details-paper-text">
                                            {data.Registation_year || "n/a"}
                                        </span>
                                    </p>
                                    <p
                                        style={{
                                            marginTop: 1,
                                            marginBottom: "3px",
                                        }}
                                    >
                                        SMEDAN Registered:{" "}
                                        <span className="details-paper-text">
                                            {data.SMEDAN_registered || "n/a"}
                                        </span>
                                    </p>
                                    <p style={{ marginTop: 1 }}>
                                        SMEDAN Number:{" "}
                                        <span className="details-paper-text">
                                            {data.SMEDAN_number || "n/a"}
                                        </span>
                                    </p>
                                </Paper>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Box>
        </div>
    );
};

export default DetailsPage;
