import React from "react";
import { Link } from "react-router-dom";
import { AppBar, Toolbar, Typography, IconButton } from "@mui/material";
import { Home, BarChart, Description } from "@mui/icons-material";
//@ts-ignore
import bgImage from "../assets/images/bg-small.png";

const Header = ({ title, page }) => {
    return (
        <AppBar
            position="static"
            sx={{
                backgroundImage: `url(${bgImage})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                px: page === "home" ? 10 : "",
            }}
        >
            <Toolbar>
                <Typography variant="h6" component="span" sx={{ flexGrow: 1 }}>
                    {title}
                </Typography>
                {page === "data" && (
                    <>
                        <IconButton
                            component={Link}
                            to="/"
                            color="inherit"
                            edge="end"
                            aria-label="Home"
                            sx={{ mr: 3 }}
                        >
                            <Home />
                            <Typography variant="body1">Home</Typography>
                        </IconButton>

                        <IconButton
                            component={Link}
                            to="/analysis"
                            color="inherit"
                            edge="end"
                            aria-label="Analysis"
                        >
                            <BarChart />
                            <Typography variant="body1">Analysis</Typography>
                        </IconButton>
                    </>
                )}
                {page === "analysis" && (
                    <>
                        <IconButton
                            component={Link}
                            to="/"
                            color="inherit"
                            edge="end"
                            aria-label="Home"
                            sx={{ mr: 3 }}
                        >
                            <Home />
                            <Typography variant="body1">Home</Typography>
                        </IconButton>

                        <IconButton
                            component={Link}
                            to="/data"
                            color="inherit"
                            edge="end"
                            aria-label="Data"
                            sx={{ mr: 3 }}
                        >
                            <Description />
                            <Typography variant="body1">Data</Typography>
                        </IconButton>
                    </>
                )}
                {page === "home" && (
                    <>
                        <IconButton
                            component={Link}
                            to="/data"
                            color="inherit"
                            edge="end"
                            aria-label="Data"
                            sx={{ mr: 3 }}
                        >
                            <Description />
                            <Typography variant="body1">Data</Typography>
                        </IconButton>

                        <IconButton
                            component={Link}
                            to="/analysis"
                            color="inherit"
                            edge="end"
                            aria-label="Analysis"
                        >
                            <BarChart />
                            <Typography variant="body1">Analysis</Typography>
                        </IconButton>
                    </>
                )}
            </Toolbar>
        </AppBar>
    );
};

export default Header;
