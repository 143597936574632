import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./assets/styles/main.scss";
import Home from "./pages/Home";
import MainDataComponent from "./pages/Data";
import DetailsPage from "./pages/DetailsPage";
import CircularProgress from "@mui/material/CircularProgress";
import Analysis from "./pages/Analysis";

export function App(): JSX.Element {
    return (
        <Router>
            <Suspense fallback={<CircularProgress />}>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/data" element={<MainDataComponent />} />
                    <Route path="/data/:id" element={<DetailsPage />} />
                    <Route path="/analysis" element={<Analysis />} />
                </Routes>
            </Suspense>
        </Router>
    );
}
